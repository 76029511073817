import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"
import IconMetaRow from "../../components/IconMetaRow"

export const pageQuery = graphql`
  query {
    northHighlandsImage: file(relativePath: { eq: "north-highlands.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fixingDoorLockImage: file(relativePath: { eq: "fixing-door-lock.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function NorthHighlandsPage({ data }) {
  return (
    <MainLayout
      title="24 Hr Expert Locksmith - North Highlands, CA | 916-995-0135"
      description="Locked out? Need a lock repaired or an ignition key replacement? Contact us today for immediate,  affordable 24 hour locksmith services."
      offset
    >
      <ContactHeading
        background={data.northHighlandsImage.childImageSharp.fluid}
      >
        <h1 className="text-center">North Highlands Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in North Highlands
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">
          Reliable, Affordable Locksmith in North Highlands, CA
        </h2>
        <p className="indent">
          Welcome to Swift Locksmith North Highlands. We are a family owned
          locksmith company that has been providing first class, dependable and
          affordable locksmith services across North Highlands, CA for more than
          a decade! We are completely licensed, bonded and insured. We have a
          considerable amount of knowledge and expertise for all elements of
          vehicle, commercial and residential locksmith security. Our friendly
          and professional locksmith technicians provide mobile locksmith
          services for the North Highlands community, 24 hours a day, 7 days a
          week. We pride ourselves on constantly providing inexpensive,
          dependable, and fast locksmith service that you can trust.
        </p>
        <p className="indent mb-0">
          Our expert team of technicians are able to come within 30 minutes of
          your call to your location. Our mobile locksmith vans are built with
          all of the very best, high tech tools and are carried with a multitude
          of quality hardware and locks to meet all of your security and
          locksmith emergency needs. Whether you need emergency locksmith
          services or maybe need residential or business protection needs, Swift
          Locksmith in North Highland, CA is able to supply the optimum level of
          service with the best price tag.
        </p>
      </Container>
      <Container as="section">
        <h2>Why Choose Swift Locksmith North Highlands, CA</h2>
        <p className="indent">
          By picking Swift Locksmith in North Highlands, CA, you are placing
          expertise to work exactly where it counts most: the safety for you and
          the security for your home or business. With over ten years of
          experience in the locksmith industry, our locksmiths provide in depth
          automotive, residential, and commercial locksmith service for the
          North Highlands community. We have all the tools necessary to resolve
          all of your locksmith needs.
        </p>
        <p className="indent md-0">
          Because we are a 24/7 mobile locksmith company, our team is always
          available for you. Its important to find a locksmith who you can
          depend on at any given moment and we are your company that you can
          always trust to respond to you quickly, resolve any locksmith issue
          you may have, all while having a smile on our face!
        </p>
      </Container>
      <Container as="section" className="text-primary">
        <IconMetaRow />
      </Container>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>North Highland's Residential Services</h2>
        <Row className="mb-5">
          <Col xs={12} md={{ span: 6, order: 2 }} xl={7}>
            <p className="indent">
              It is essential to get a feeling of security in your own home and
              the goal is providing you with the very best residential locksmith
              solutions. We provide various kinds of locks such as dead bolts,
              knob sets and wireless locks so that you are able to choose what
              works best for you and your home. Our technicians provide
              different security services from basic to high security as well
              options to support various budgets. If you get locked out from
              your home, need to restore locks, or want to update your home
              security and are unsure what the best options are, call Swift
              Locksmith and the knowledgeable staff of ours will happily offer
              help and options for you.
            </p>
          </Col>
          <Col xs={12} md={{ span: 6, order: 1 }} xl={5} className="col-sm-pad">
            <Img
              fluid={data.fixingDoorLockImage.childImageSharp.fluid}
              alt="fixing-door-lock"
            />
          </Col>
        </Row>
        <h2>North Highland's Automotive Services</h2>
        <Row className="mb-5">
          <Col xs={12} md={{ span: 6, order: 2 }} xl={7}>
            <p className="indent">
              We provide 24/7 emergency automobile services to North Highlands,
              CA and neighboring areas. Our technicians have been doing this for
              a long time and are experts in the car locksmith field. We service
              all kinds of makes and models so you can be sure that we will help
              you out. From helping you get back into your car, extracting your
              keys to programming your transponder key, Swift Locksmith
              technicians will take care of any automobile locksmith issue
              efficiently and quickly.
            </p>
          </Col>
          <Col xs={12} md={{ span: 6, order: 1 }} xl={5} className="col-sm-pad">
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
            />
          </Col>
        </Row>
        <h2>North Highland's Commercial Services</h2>
        <Row>
          <Col xs={12} md={{ span: 6, order: 2 }} xl={7}>
            <p className="indent">
              You need to ensure your business is completely protected all the
              times and we are able to offer you high quality locksmith
              protection. Our technicians give you a personalized experience for
              every customer according to what they're searching for so we
              always have the your needs in mind. We use the latest technologies
              to supply better and newer features for business security. From
              business lockouts, changing/repairing locks to electric pads, we
              have you covered. Let us take care of your security needs and
              provide you with the best commercial locksmith solutions so you
              can have more time to focus on your business.
            </p>
          </Col>
          <Col xs={12} md={{ span: 6, order: 1 }} xl={5} className="col-sm-pad">
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default NorthHighlandsPage
